"use client";

import React, { useContext } from "react";
import { useRouter } from "next/navigation";
import * as Sentry from "@sentry/nextjs";
import DropdownLink from "@components/Navigation/Navbar/DropdownBox/DropdownLink";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { CartContext } from "@utils/Cart/cartProvider";
import { logout } from "@db/Actions/auth";

export const LogoutButton = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { removeRetailer } = useContext(CartContext);
  const router = useRouter();

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      await logout();
      removeRetailer();

      if (window.location.pathname === "/") {
        router.push("/");
      } else {
        window.location.reload();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <DropdownLink onClick={(e) => handleLogout(e)} href="/logout" label={dictionary.logout} dark prefetch={false} />
  );
};
